import React from "react";
import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";

const QuadroQSO = ({ data }) => {
  const styles = {
    menu: {
      background: "#0E2352",
      color: "#fff",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 14,
      fontWeight: 600,
      padding: 1,
      border: "1px solid #000",
      boxSizing: "border-box",
    },
    box: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
    },
    content: {
      background: "#fff",
      color: "#000",
      padding: 1,
      border: "1px solid #000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 12.3,
      fontWeight: 600,
      flex: 1,
      boxSizing: "border-box",
    },
    celSelect: {
      backgroundColor: "#e2d718",
      color: "#411a0e",
    },
  };

  const getCelulaSelecionadaStyle = (array, targets) => {
    if (array && array.some((item) => targets.includes(item))) {
      return styles.celSelect;
    }
    return {};
  };

  return (
    <Box
      sx={{
        fontFamily: "Montserrat",
        width: "1150px",
        minWidth: "1150px",
        height: "800px",
        minHeight: "100%",
        overflow: "auto",
      }}
    >
      <Grid container spacing={0.7} sx={{ height: "100%", alignItems: "stretch" }}>
        <Grid size={2} sx={styles.box}>
          <Box
            sx={{
              ...styles.menu,
              fontSize: 16,
              background: "linear-gradient(90deg, #0E2352 0%, #002984 100%)",
              borderTopLeftRadius: 10,
            }}
          >
            TRIBUTO
          </Box>
          <Box sx={{ ...styles.menu, fontSize: 13, paddingBlock: 3.4 }}>
            NATUREZA JURÍDICA DO CONTRATANTE
          </Box>

          <Stack direction="row" spacing={0} sx={{ flex: 0.5 }}>
            <Box sx={{ ...styles.menu, width: "15%" }}>A</Box>
            <Box
              sx={{
                ...styles.menu,
                background: "linear-gradient(to right, #97B4D8, #FAF6F6)",
                color: "#000",
                width: "85%",
              }}
            >
              Órgãos, Autarquias e Fundações FEDERAIS
            </Box>
          </Stack>
          <Stack direction="row" spacing={0} sx={{ flex: 0.5 }}>
            <Box sx={{ ...styles.menu, width: "15%" }}>B</Box>
            <Box
              sx={{
                ...styles.menu,
                background: "linear-gradient(to right, #97B4D8, #FAF6F6)",
                color: "#000",
                width: "85%",
              }}
            >
              Empresas Públicas e Sociedades de Economia Mista FEDERAIS
            </Box>
          </Stack>
          <Stack direction="row" spacing={0} sx={{ flex: 1 }}>
            <Box sx={{ ...styles.menu, width: "15%" }}>C</Box>
            <Box
              sx={{
                ...styles.menu,
                background: "linear-gradient(to right, #97B4D8, #FAF6F6)",
                color: "#000",
                width: "85%",
              }}
            >
              Órgãos, Autarquias e Fundações ESTADUAIS e MUNICIPAIS
            </Box>
          </Stack>
          <Stack direction="row" spacing={0} sx={{ flex: 1 }}>
            <Box sx={{ ...styles.menu, width: "15%", fontSize: 14, borderBottomLeftRadius: 10 }}>
              D
            </Box>
            <Box
              sx={{
                ...styles.menu,
                background: "linear-gradient(to right, #97B4D8, #FAF6F6)",
                color: "#000",
                width: "85%",
              }}
            >
              Empresas ESTADUAIS e MUNICIPAIS, Empresas Privadas e Entidades Particulares Sem Fins
              Lucrativos
            </Box>
          </Stack>
        </Grid>
        <Grid size={3.5} sx={styles.box}>
          <Box sx={{ ...styles.menu, background: "#2B467D" }}>1</Box>
          <Box sx={{ ...styles.menu, background: "#2B467D", paddingBlock: 2.3 }}>
            Contribuição Previdenciária (INSS)
          </Box>
          <Stack direction="row" spacing={0} sx={{ flex: 1 }}>
            <Stack direction="column" spacing={0} sx={{ flex: 1 }}>
              <Box sx={{ ...styles.menu, background: "#2B467D" }}>1.1</Box>
              <Box
                sx={{
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.inss1, ["A", "B", "C", "D"]),
                }}
              >
                Retenção na contratação de PESSOAS JURÍDICAS de determinados serviços executados
                mediante cessão de mão de obra ou empreitada
              </Box>
            </Stack>
            <Stack direction="column" spacing={0} sx={{ flex: 1 }}>
              <Box sx={{ ...styles.menu, background: "#2B467D" }}>1.2</Box>
              <Box
                sx={{
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.inss2, ["A", "B", "C", "D"]),
                }}
              >
                Contribuição patronal sobre a contratação de cooperativas de trabalho
              </Box>
            </Stack>
            <Stack direction="column" spacing={0} sx={{ flex: 1 }}>
              <Box sx={{ ...styles.menu, background: "#2B467D" }}>1.3</Box>
              <Box
                sx={{
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.inss3, ["A", "B", "C", "D"]),
                }}
              >
                Retenção e contribuição patronal sobre a contratação de PESSOAS FÍSICAS enquadradas
                como Contribuintes Individuais
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid size={2.5} sx={styles.box}>
          <Box sx={{ ...styles.menu, background: "#2D60A3" }}>2</Box>
          <Box sx={{ ...styles.menu, background: "#2D60A3" }}>
            Imposto de Renda Retido na Fonte - (IRRF)
          </Box>
          <Stack direction="row" spacing={0} sx={{ flex: 1 }}>
            <Stack direction="column" spacing={0} sx={{ flex: 1 }}>
              <Box sx={{ ...styles.menu, background: "#2D60A3" }}>2.1</Box>
              <Box
                sx={{
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.irrf1, ["A", "B", "C", "D"]),
                }}
              >
                Retenção nos pagamentos a PESSOAS FÍSICAS, conforme Regulamento do Imposto de Renda
                (RIR/2018)
              </Box>
            </Stack>
            <Stack direction="column" spacing={0} sx={{ flex: 1.5 }}>
              <Box sx={{ ...styles.menu, background: "#2D60A3" }}>2.2</Box>
              <Box
                sx={{
                  flex: 1,
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.irrf2, ["A", "B"]),
                }}
              >
                Retenção nos pagamentos a PESSOAS JURÍDICAS, conforme IN RFB 1.234/12
              </Box>
              <Box
                sx={{ flex: 1, ...styles.content, ...getCelulaSelecionadaStyle(data.irrf2, ["C"]) }}
              >
                Retenção na contratação de PESSOA JURÍDICA, conforme art. 64 da Lei nº 9.430/96.
              </Box>
              <Box
                sx={{
                  flex: 1,
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.irrf2, ["D"]),
                }}
              >
                Retenção na contratação de PESSOA JURÍDICA, conforme Decreto nº 9.580/2018
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid size={1.5} sx={styles.box}>
          <Box sx={{ ...styles.menu, background: "#2984D7" }}>3</Box>
          <Box sx={{ ...styles.menu, paddingBlock: 0.8, background: "#2984D7" }}>
            Cont. Sociais (CSLL, PIS/Pasep e Cofins)
          </Box>
          <Stack direction="row" spacing={0} sx={{ flex: 1 }}>
            <Stack direction="column" spacing={0} sx={{ flex: 1.5 }}>
              <Box
                sx={{
                  flex: 1,
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.csllPisCofins, ["A", "B"]),
                }}
              >
                Retenção nos pagamentos a PESSOAS JURÍDICAS conforme IN RFB 1.234/12
              </Box>
              <Box
                sx={{
                  flex: 1,
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.csllPisCofins, ["C"]),
                }}
              >
                Não há obrigação de proceder à retenção das PESSOAS JURÍDICAS se não houver convênio
              </Box>
              <Box
                sx={{
                  flex: 1,
                  ...styles.content,

                  ...getCelulaSelecionadaStyle(data.csllPisCofins, ["D"]),
                }}
              >
                Retenção nos pgtos. a PESSOAS JURÍDICAS relativos a determinados serviços, conforme
                art. 30 da Lei 10.833/03 e IN SRF 459/2004
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid size={2.5} sx={styles.box}>
          <Box sx={{ ...styles.menu, background: "#63ADF2", borderTopRightRadius: 10 }}>4</Box>
          <Box sx={{ ...styles.menu, background: "#63ADF2", paddingBlock: 2.3 }}>
            Imposto Sobre Serviços (ISS)
          </Box>
          <Stack direction="row" spacing={0} sx={{ flex: 1, marginRight: 0 }}>
            <Stack direction="column" spacing={0} sx={{ flex: 1 }}>
              <Box sx={{ ...styles.menu, background: "#63ADF2" }}>4.1</Box>
              <Box
                sx={{
                  ...styles.content,
                  ...getCelulaSelecionadaStyle(data.iss1, ["A", "B", "C", "D"]),
                }}
              >
                Retenção nos serviços prestados por PESSOAS FÍSICAS observadas as regras previstas
                na LC 116/2003 e na legislação de ISS de cada Município
              </Box>
            </Stack>
            <Stack direction="column" spacing={0} sx={{ flex: 1 }}>
              <Box sx={{ ...styles.menu, background: "#63ADF2" }}>4.2</Box>
              <Box
                sx={{
                  ...styles.content,
                  borderBottomRightRadius: 10,
                  ...getCelulaSelecionadaStyle(data.iss2, ["A", "B", "C", "D"]),
                }}
              >
                Retenção nos serviços prestados por PESSOAS JURÍDICAS observadas as regras previstas
                na LC 116/2003 e na legislação de ISS de cada Município
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuadroQSO;
